import React, { useEffect, useRef, useState } from "react";
import { Feature, Map as OLMap, View } from "ol";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import "ol/ol.css";
import { useGeographic } from "ol/proj";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Point from "ol/geom/Point";
import Style from "ol/style/Style";
import Icon from "ol/style/Icon";
import { Form, InputGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./UserMapPage.scss";
import locationService from "../../services/LocationService";

const UserMapPage: React.FC = () => {
  const mapRef = useRef<HTMLDivElement | null>(null);
  const userLocationFeatureRef = useRef<Feature | null>(null);
  const previousPositionRef = useRef<{ latitude: number; longitude: number } | null>(null);
  const previousRotationRef = useRef<number>(0);
  const [locationId, setLocationId] = useState<number>(1);
  const [locationData, setLocationData] = useState<any>(null);
  const [map, setMap] = useState<OLMap | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredStores, setFilteredStores] = useState<any[]>([]);

  useGeographic();

  const userAgent = navigator.userAgent;
  
  const isIOS = /iPad|iPhone|iPod/
    .test(userAgent) && !/Windows/
    .test(userAgent) && !/Macintosh/
    .test(userAgent) && !/Win/
    .test(userAgent) && !(window as any).MSStream;

  useEffect(() => {
    const fetchLocationById = async (id: number) => {
      try {
        const result = await locationService.getById(id);
        setLocationData(result.data);
      } catch (error) {
        console.error("Error fetching location data:", error);
      }
    };

    fetchLocationById(locationId);
  }, [locationId]);

  useEffect(() => {
    if (locationData && mapRef.current) {
      const osmLayer = new TileLayer({
        source: new OSM(),
      });

      const initialView = new View({
        center: [parseFloat(locationData.longitude), parseFloat(locationData.latitude)],
        zoom: 18,
      });

      const mapInstance = new OLMap({
        target: mapRef.current,
        layers: [osmLayer],
        view: initialView,
        controls: [], 
      });

      const vectorSource = new VectorSource();
      const vectorLayer = new VectorLayer({
        source: vectorSource,
      });
      mapInstance.addLayer(vectorLayer);

      const deleteLayer = (id: string) => {
        let layersToRemove: any[] = [];
        mapInstance.getLayers().forEach((layer) => {
          if (layer && layer.get("id") === id) {
            layersToRemove.push(layer);
          }
        });
        layersToRemove.forEach((layer) => mapInstance.removeLayer(layer));
      };

      const plotUserLocation = (latitude: number, longitude: number, rotation: number) => {
        const idLayer = "current-position";
        deleteLayer(idLayer);

        const markerGeometry = new Point([longitude, latitude]);
        const markerFeature = new Feature({
          geometry: markerGeometry,
          id: idLayer,
        });

        const markerStyle = new Icon({
          src: isIOS ? "/images/current-position.png" : "/images/current-position-direction.png",
          anchor: [0.5, 40],
          anchorXUnits: "fraction",
          anchorYUnits: "pixels",
          scale: [0.2, 0.2],
          rotation: rotation,
        });

        markerFeature.setStyle(
          new Style({
            image: markerStyle,
          })
        );

        vectorSource.clear();
        vectorSource.addFeature(markerFeature);
      };

      const calculateRotation = (prevPos: { latitude: number; longitude: number }, currPos: { latitude: number; longitude: number }) => {
        const deltaX = currPos.longitude - prevPos.longitude;
        const deltaY = currPos.latitude - prevPos.latitude;
        return Math.atan2(deltaY, deltaX);
      };

      const isSignificantMovement = (prevPos: { latitude: number; longitude: number }, currPos: { latitude: number; longitude: number }) => {
        const toRadians = (degrees: number) => degrees * (Math.PI / 180);
      
        const R = 6371000;
        const dLat = toRadians(currPos.latitude - prevPos.latitude);
        const dLon = toRadians(currPos.longitude - prevPos.longitude);
        const lat1 = toRadians(prevPos.latitude);
        const lat2 = toRadians(currPos.latitude);
      
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                  Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      
        const distance = R * c;
      
        return distance > 0.5;
      };

      const startTrackingUserLocation = () => {
        if (navigator.geolocation) {
          navigator.geolocation.watchPosition(
            (position) => {
              const { latitude, longitude } = position.coords;

              let rotation = previousRotationRef.current;
              if (previousPositionRef.current && isSignificantMovement(previousPositionRef.current, { latitude, longitude })) {
                rotation = calculateRotation(previousPositionRef.current, { latitude, longitude });
                previousRotationRef.current = rotation;
              }

              plotUserLocation(latitude, longitude, rotation);

              previousPositionRef.current = { latitude, longitude };
              mapInstance.getView().setCenter([longitude, latitude]);
            },
            (error) => {
              console.error("Error getting user location:", error);
            },
            {
              enableHighAccuracy: true,
              timeout: 5000,
              maximumAge: 0,
            }
          );
        } else {
          console.error("Geolocation is not supported by this browser.");
        }
      };

      const handleDeviceOrientation = (event: DeviceOrientationEvent) => {
        if (event.alpha !== null) {
          const rotation = (event.alpha * Math.PI) / 180; 
          previousRotationRef.current = rotation;
          if (previousPositionRef.current) {
            plotUserLocation(previousPositionRef.current.latitude, previousPositionRef.current.longitude, rotation);
          }
        }
      };

      window.addEventListener("deviceorientation", handleDeviceOrientation, true);

      setMap(mapInstance);

      startTrackingUserLocation();

      return () => {
        mapInstance.setTarget("");
        window.removeEventListener("deviceorientation", handleDeviceOrientation);
      };
    }
  }, [locationData]); 

  useEffect(() => {
    if (locationData && locationData.stores) {
      const filtered = locationData.stores.filter((store: any) =>
        store.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredStores(filtered);
    } else {
      setFilteredStores([]);
    }
  }, [searchTerm, locationData]);

  return (
    <div className="map-container">
      <div className="search-container">
        <InputGroup className="search-input-group">
          <Form.Control
            type="text"
            placeholder="Procure por nome de loja"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
          <InputGroup.Text className="search-icon">
            <i className="bi bi-search"></i>
          </InputGroup.Text>
        </InputGroup>
        {searchTerm && filteredStores.length > 0 && (
          <div className="search-results">
            {filteredStores.map((store) => (
              <div key={store.id} className="search-result-item">
                {store.name}
              </div>
            ))}
          </div>
        )}
      </div>
      <div ref={mapRef} className="map-container" />
    </div>
  );
};

export default UserMapPage;